@use 'src/styles/main.scss';
@use 'src/styles/6-abstracts/variables' as var;

.wrapper {
  width: 100%;
  // height: 100vh;
  min-height: 100vh;
  padding: 20px 37px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
  font-family: var.$font-family-primary;
  background-color: var.$background-color-third;
  backdrop-filter: blur(10px);
  color: var.$font-color-primary;
  cursor: default;

  .title {
    width: 100%;
    height: 60px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: var.$font-family-primary-bold;
    font-size: var.$font-size-xl;
    background-color: var.$background-color-primary;
    border-radius: var.$border-radius-large;
    border: 1px solid var.$border-color-primary;
    gap: 20px;

    .logo {
      width: 35px;
      height: 35px;
    }
  }

  .subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;

    .info {
      display: flex;
      flex-direction: row;
      gap: 30px;

      .item {
        display: flex;
        flex-direction: column;
        gap: 5px;
  
        .item_title {
          color: var.$font-color-third;
          font-size: var.$font-size-s;
        }
        .item_body {
          font-size: var.$font-size-l;
        }
      }
    }

    .menuBar {
      display: flex;
      flex-direction: row;
      gap: 30px;
    }

  }

  .body {
    // padding-top: 10px;
    height: auto;
    overflow-x: auto;
    white-space: nowrap;
    animation: all 0.3s;

    .body_wrapper{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 15px;
      margin: 10px 0px;
  
      .body_element {
        // width: 200px;
        // height: 200px;
        background-color: var.$background-color-blue;
        border-radius: var.$border-radius-large;
        cursor: pointer;
  
      }
    }
  }
  

  .menuBar {
    display: flex;
    justify-content: space-between;
  }

  .tasks {
    display: flex;
    flex-direction: column;
  }

  .standby_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .takeOff_wrapper {
    width: 100%;
    background-color: blue;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .standby {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: red;
      font-size: 40px;
    }
  
    .button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: var.$font-size-xl;
    }
  }

}

@media screen and (max-width: 1050px) {
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // padding: 50px 50px;
  // height: 100%;

  // .title {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   gap: 10px;
  //   margin-bottom: 40px;
  //   // font-size: 30px; 

  //   .logo {
  //     width: 30px;
  //     height: 30px;
  //   }
  // }
  .wrapper{ .body{
    .body_wrapper{
      // display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;

      // justify-items: center;
      // gap: 30px;
      // background-color: red;
    }
  }}
}


