@use 'src/styles/6-abstracts/variables' as var;

.pinnedHubCard {
  display: flex;
  gap: 10px;
  overflow: hidden;
  background: var.$background-color-primary;
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  font-family: var.$font-family-primary;

  .hubGeneralInfo {
    position: relative;
    width: 220px;
    min-height: 360px;
    overflow: hidden;
    background-color: black;

    .coverImg {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      opacity: 80%;
      inset: 0;
      object-fit: cover;
    }

    .generalInfo {
      position: relative;
      z-index: 1;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 10px;
      font-family: var.$font-family-primary-bold;
      font-size: 16px;
      color: var.$font-color-secondary;
      text-shadow: 0 0 2px var.$background-color-secondary;

      .hubStatus {
        width: 15px;
        height: 15px;
        background-color: currentColor;
        box-shadow: 0 0 2px var.$background-color-secondary;
        border-radius: 50%;
      }
    }
  }

  .hubOperationInfo {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    // background-color: red;
  }

  .iconBar {
    padding: 15px 15px 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .pinIcon {
      width: 20px;
      height: 20px;
      color: var.$color-blue;
    }
  }

  .actionBtn {
    width: 100px;
    padding: 4px 9px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    color: white;
    background-color: var.$background-color-neutral-light;
    
    &.active {
      background-color: var.$color-blue;
    }
  
    &.inactive {
      pointer-events: none;
      cursor: default;
    }
  }


  @media screen and (max-width: 775px) {
    height: 400px;
    flex-direction: column;
    align-items: flex-start;

    .hubGeneralInfo {
      min-height: 100px;
      height: 120px;
      width: 100%;
    }

    .hubOperationInfo {
      width: 100%;
      padding: 10px 20px;
    }

    .iconBar {
      flex-direction: row;
      align-items: center;   
      width: 100%;

      .pinIcon{ margin-left: 20px;}
    }

  }

}

.inAdvance {
  width:fit-content;
  display: flex;
  padding: 1px 6px;
  background-color: rgb(#4caf50, 15%);
  color: #4caf50;
  border-radius: 4px;
  font-size: var.$font-size-xs;
}
.late {
  width:fit-content;
  display: flex;
  padding: 1px 6px;
  background-color: rgb(253, 235, 212);
  color: rgb(217, 105, 6);
  border-radius: 4px;
  font-size: var.$font-size-xs;
}
.timeAdvance {
  color: var.$color-blue;
}
.timeLate {
  color: var.$color-orange;
}



// .textCell {
  // add style for cell
// }