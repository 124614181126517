@use '../6-abstracts/variables' as var;

.sidebar {
  position: sticky;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var.$background-color-primary;
  border: 1px solid var.$border-color-primary;
  box-sizing: border-box;
  font-family: var.$font-family-primary-bold;
  font-size: var.$font-size-m;
  color: var.$font-color-primary;
  transition: width 0.5s ease-in-out;

  .item_link_label {
    display: block;
  }

  .header_wrapper {
    height: 110px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    &:hover {
      cursor: pointer;
    }

    img {
      max-width: 120px;
      // min-height: 60px;
      object-fit: contain;

      &:hover {
        cursor: pointer;
      }
    }

    .header_logo {
      width: 100%;
      min-width: 59px;
      height: auto;
      min-height: 60px;
      aspect-ratio: 1;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .body_wrapper {
    width: 200px;
    display: flex;
    flex-direction: column;
    // border-top: solid;
    // border-width: 1px;
    // border-color: var.$border-color-primary;

    .body_section {
      margin: 25px auto;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 6px;
    }

    .section_title {
      display: block;
      padding: 0 16px;
      font-family: var.$font-family-primary-ultra-bold;
      font-size: var.$font-size-s;
    }

    .section_nav {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .section_nav > div {
      width: 170px;
      padding: 10px 16px;
      display: flex;
      gap: 16px;
      align-content: center;
      border-radius: var.$border-radius-small;
      box-shadow: none;
      user-select: none;

      &:hover {
        background-color: var.$background-color-third;
        cursor: pointer;
      }
    }

    .active_item {
      background-color: var.$background-color-third;
    }
  }

  .footer_wrapper {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
    // border-top: solid;
    // border-width: 1px;
    // border-color: var.$border-color-primary;
    font-size: var.$font-size-s;
    margin: 0px auto;

    .footer {
      position: sticky;
      bottom: 25px;
      margin-top: 10px;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 3px;
    }

    .footer > div {
      width: 170px;
      padding:  8px 16px;
      display: flex;
      gap: 16px;
      border-radius: 0.5rem;
      box-shadow: none;

      &:hover {
        background-color: var.$background-color-third;
        cursor: pointer;
      }
    }

    .active_item {
      background-color: var.$background-color-third;
    }
  }

  @media (max-width: 1000px) {
    display: none;
  }
}

.mobileSidebar {
  width: 100vw;
  height: 100vh;
  background-color: var.$background-color-secondary;
  color: var.$font-color-secondary;
  font-family: var.$font-family-primary-bold;
  font-size: var.$font-size-m;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: var.$cursor-secondary;

  .header_wrapper {
    height: 110px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 6px;

    &:hover {
      cursor: pointer;
    }

    img {
      min-width: 59px;
      min-height: 60px;
      object-fit: contain;

      &:hover {
        cursor: pointer;
      }
    }

    .header_logo {
      width: 100%;
      min-width: 59px;
      height: auto;
      min-height: 60px;
      aspect-ratio: 1;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .body_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border-top: solid;
    // border-width: 1px;
    // border-color: var.$border-color-primary;

    .body_section {
      margin: 25px auto;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
    }

    .section_title {
      display: block;
      text-align: center;
      padding: 0 16px;
      font-family: var.$font-family-primary-ultra-bold;
      font-size: var.$font-size-s;
    }

    .section_nav {
      display: flex;
      flex-direction: column;
      gap: 10px;
      
    }

    .section_nav > div {
      width: 250px;
      padding: 10px 16px;
      display: flex;
      gap: 16px;
      align-content: center;
      border-radius: var.$border-radius-medium;
      box-shadow: none;
      user-select: none;
      justify-content: center;
      font-family: var.$font-family-primary;
      font-size: var.$font-size-3xl;

      &:hover {
        background-color: rgb(var.$background-color-third, 5%);
        cursor: pointer;
      }
    }

    .active_item {
      // background-color: rgb(var.$background-color-third, 100%);
      color: var.$background-color-blue;
    }
  }

  .footer_wrapper {
    margin: 25px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    // .footer {
    //   position: sticky;
    //   bottom: 25px;
    //   margin-top: 10px;
    //   padding: 0;
    //   display: flex;
    //   flex-direction: column;
    //   gap: 3px;
    // }

    // .footer > div {
    //   display: flex;
    //   gap: 16px;
    //   border-radius: 0.5rem;
    //   box-shadow: none;

    //   &:hover {
    //     background-color: var.$background-color-third;
    //     cursor: pointer;
    //   }
    // }
  }
}
