@use 'src/styles/2-components/buttons';
@use 'src/styles/6-abstracts/variables' as var;

// Define CSS variables for mui DataGrid Cell design

$statusColorPending: var.$color-pending;
$statusColorScheduled: var.$color-scheduled;
$statusColorApproved: var.$color-approved;
$statusColorComing: var.$color-coming;
$statusColorReady: var.$color-ready;
$statusColorLoading: var.$color-loading;
$statusColorLoaded: var.$color-loaded;
$statusColorInflight: var.$color-in-flight;
$statusColorReadyToUnload: var.$color-ready-to-unload;
$statusColorUnloading: var.$color-unloading;
$statusColorCompleted: var.$color-completed;
$statusColorCancelled: var.$color-canceled;
$statusColorAborted: var.$color-aborted;
$colorBlue: var.$color-blue;

:export {
  statusColorPending: $statusColorPending;
  statusColorScheduled: $statusColorScheduled;
  statusColorApproved: $statusColorApproved;
  statusColorComing: $statusColorComing;
  statusColorReady: $statusColorReady;
  statusColorLoading: $statusColorLoading;
  statusColorLoaded: $statusColorLoaded;
  statusColorInflight: $statusColorInflight;
  statusColorReadyToUnload: $statusColorReadyToUnload;
  statusColorUnloading: $statusColorUnloading;
  statusColorCompleted: $statusColorCompleted;
  statusColorCancelled: $statusColorCancelled;
  statusColorAborted: $statusColorAborted;
  colorBlue: $colorBlue;
}

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var.$font-family-primary;

  .cardModal {
    // min-width: clamp(300px, 80%, 650px);
    min-width: 650px;
    max-height: 80%;
    // padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var.$background-color-third;
    border-radius: var.$border-radius-large;
    color: var.$font-color-primary;

    .header {
      width: 100%;
      padding: 15px 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: var.$font-family-primary-ultra-bold;
      font-size: var.$font-size-l;
      text-align: center;

      .statusHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        font-size: var.$font-size-m;

        .dot {
          width: 12px;
          height: 12px;
          background-color: currentColor;
          border-radius: 50%;
        }
      }

      .close_icon {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var.$font-size-m;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          color: var.$color-blue;
        }
      }
    }

    .flight_route {
      width: 100%;
      padding: 10px 30px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: rgb(var.$background-color-blue, 10%);
      color: var.$color-blue;

      .hub_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        width: 200px;
        font-size: var.$font-size-s;

        .hub_name {
          font-size: var.$font-size-xl;
          font-family: var.$font-family-primary-bold;
          color: var.$color-blue;
        }
      }
    }

    .content {
      position: relative;
      width: 100%;
      padding: 20px 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      // font-family: var.$font-family-primary;
      // font-size: var.$font-size-l;

      .info_card {
        display: flex;
        flex-direction: column;
        width: 300px;
        gap: 15px;
        padding: 10px 0px 10px 0px;

        .info_card_header {
          font-family: var.$font-family-primary-ultra-bold;
          color: var.$color-blue;
          font-size: var.$font-size-s;
        }

        .info_card_content {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 25px;
        }
      }

      .info_card_shipment {
        z-index: 1;
        width: 300px;
        display: flex;
        flex-direction: column;
        padding: 10px 0px 10px 20px;
        // &:hover {
        //   opacity: 50%;
        // }

        .info_card_header_shipment {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-family: var.$font-family-primary-ultra-bold;
          color: var.$color-blue;
          font-size: var.$font-size-s;
          margin-bottom: 15px;
        }
        .info_card_content_shipment {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 25px;
        }
      }
      .editButton {
        z-index: 2;
        position: absolute;
        top: 20px;
        right: 30px;
        background-color: rgba(229, 229, 229, 0.5);
        width: 270px;
        height: 135px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var.$border-radius-small;
      }

    }

    .confirmation_header {
      width: 100%;
      padding: 40px 30px 0 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: var.$font-family-primary-ultra-bold;
      font-size: var.$font-size-2xl;
      text-align: center;
    }

    .confirmation_content {
      // width: 100%;
      padding: 40px 30px;
      display: flex;
      gap: 40px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: var.$font-family-primary;
      font-size: var.$font-size-l;
    }

    .editPayload_wrapper {

    }

    .footer {
      width: 100%;
      padding: 0px 30px 20px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: var.$font-family-primary;
      font-size: var.$font-size-m;
      text-align: center;

      .cancelButton {
        padding: 5px 8px;
        background-color: #eb5a5a;
        border: none;
        border-radius: 5px;
        outline: none;
        color: var.$font-color-secondary;
        font-family: var.$font-family-primary-bold;
        text-transform: capitalize;

        &:disabled {
          opacity: 20%;
        }

        &:hover {
          background-color: #ff0000;
          color: var.$font-color-secondary;
        }
      }
      .rightButtons{
        display: flex;
        flex-direction: row;
        align-items: center; justify-content: center;
        gap: 5px;

        .downloadButton{
          display: flex; align-items: center; justify-content: center;
          width: 30px; height: 30px;
          border-radius: 50%;
          margin-right: -5px;

          &:hover{
            background-color: rgba(var.$background-color-blue, 0.1);
            color: var.$color-blue;
            cursor: pointer;
          }
        }
        .disabledDownloadButton{
          display: flex; align-items: center; justify-content: center;
          width: 30px; height: 30px;
          opacity: 50%;
          pointer-events: none;
          margin-right: -5px;
        }

        .editButtonWrapper{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
  
          .hiddenButton {
            display: flex;
            flex-direction: row;
            align-items: center;
            // gap: 20px;
          }
          .editButton {
            display: flex; align-items: center; justify-content: center;
            width: 30px; height: 30px;
            border-radius: 50%;
          }
          .editButton_hover{
            display: flex; align-items: center; justify-content: center;
            width: 30px; height: 30px; border-radius: 50%;
            background-color: rgba(var.$background-color-blue, 0.1);
            color: var.$color-blue;
          }
        }
        .disabledButton {
          opacity: 50%;
          pointer-events: none;
        }
      }
      

      .prepareButton {
        padding: 5px 8px;
        background-color: #eb5a5a;
        border: none;
        border-radius: 5px;
        outline: none;
        color: var.$font-color-secondary;

        &:disabled {
          opacity: 20%;
        }

        &:hover {
          background-color: #ff0000;
          color: var.$font-color-secondary;
        }
      }

      .cancelCancellation {
        padding: 5px 8px;
        background-color: rgba(var.$background-color-fifth, 0.5);
        border: none;
        border-radius: 5px;
        outline: none;
        color: var.$font-color-primary;

        &:hover {
          background-color: rgba(var.$background-color-fifth, 1);
          color: var.$font-color-primary;
        }
      }
    }
  }

  .editWrapper {
    width: clamp(300px, 80%, 650px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var.$background-color-third;
    border-radius: var.$border-radius-large;
    color: var.$font-color-primary;
  }

}

.itemDetail {
  width: max-content;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .itemKey {
    justify-self: flex-start;
    text-align: left;
    font-size: var.$font-size-xs;
    text-transform: capitalize;
  }

  .itemValue {
    justify-self: flex-start;
    font-family: var.$font-family-primary-bold;
    text-align: left;
    display: -webkit-box; /* For WebKit-based browsers */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    max-width: 150px; /* Maximum width */
    overflow: hidden;
    text-overflow: ellipsis; /* Show ellipsis (...) if text overflows */
  }
}
