@use 'src/styles/2-components/modal';
@use 'src/styles/2-components/buttons';
@use 'src/styles/6-abstracts/variables.scss' as var;


$fontFamily: var.$font-family-primary;
$fontSize: var.$font-size-s;

:export {
  fontFamily: $fontFamily;
  fontSize: $fontSize;
}
.succMsg{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  margin-bottom: 40px;
  gap: 10px;
  color: var.$color-success;
  font-family: var.$font-family-primary-bold;
  font-size: var.$font-size-l;
}
.errMsg{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  margin-bottom: 40px;
  gap: 10px;
  font-family: var.$font-family-primary-bold;
  font-size: var.$font-size-l;
  color: var.$color-canceled;
}
.addProductModal {
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var.$background-color-primary; //rgb(var.$background-color-blue, 10%);
  border-radius: var.$border-radius-large;
  border: 1px solid var.$border-color-primary;
  font-family: var.$font-family-primary;
  color: var.$font-color-primary;

  .header {
    width: 100%;
    // margin: 30px 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: var.$font-size-l;
    font-family: var.$font-family-primary-ultra-bold;
    text-align: center;

    .buttonGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .cancelButton,
      .addButton {
        width: 90px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var.$background-color-sixth;
        border-radius: 10px;
        font-size: var.$font-size-l;
        font-family: var.$font-family-primary-ultra-bold;
        cursor: pointer;
      }

      .cancelButton {
        border: 0;
        color: dimgray;

        &:hover {
          color: var.$font-color-secondary;
        }
      }

      .addButton {
        border: 1px solid var.$font-color-secondary;
        color: var.$font-color-secondary;

        &:hover {
          background: var.$background-color-primary;
          color: var.$font-color-primary;
        }
      }
    }
  }

  .addProductBody {
    // margin: 0 30px 30px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // column-gap: 100px;
    row-gap: 10px;

    .productInput {
      display: flex;
      gap: 5px;

      .propertyLabel {
        width: 110px;
        display: flex;
        align-items: center;
        text-align: left;
      }

      .propertyInput {
        width: 160px;
        padding: 0.25rem;
        background-color: transparent;
        border: 1px solid dimgray;
        border-radius: 0.25rem;
        color: white;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
    font-family: var.$font-family-primary;
    font-size: var.$font-size-m;
    text-align: center;

    .normalButton {
      padding: 12.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: var.$background-color-sixth;
      border: 1px solid var.$font-color-secondary;
      border-radius: 10px;
      font-size: var.$font-size-l;
      color: var.$font-color-secondary;
      cursor: pointer;

      &:hover {
        background: var.$background-color-fourth;
      }
    }
  }

  @media only screen and (min-width: 755px) {
    .addProductBody {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media only screen and (min-width: 1320px) {
    .addProductBody {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
