@use '../6-abstracts/variables' as var;

.body {
  max-width: 2000px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 12px;
  z-index: 1;
  overflow: hidden;

  .functionBar {
    width: 100%;
    display: flex;
    padding-right: 4px;
    justify-content: flex-end;
    gap: 12px;
  }


  .productTable {
    width: 100%;
  }

  .functionBar, .productTable {
    transition: filter 0.5s ease-out
  }

  .disabled {
    pointer-events: none;
    // filter: brightness(0%);
    opacity: 30%;
  }
}
