@use 'src/styles/2-components/modal';
@use 'src/styles/2-components/buttons';
@use 'src/styles/6-abstracts/variables' as var;

.errMsg{
  color: var.$color-canceled;
}

.orderRef {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .orderRefBody {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .refWrapper{
      display: flex; flex-direction: row; align-items: center;
      gap: 10px;
    }

    .listOperations{
      display: flex;
      flex-direction: row;
      gap: 3px;
    }

    .orderRefInput {
      position: relative;
      z-index: 1;

      input {
        padding: 0.4rem 0.8rem;
        background-color: white;
        border: 1px solid grey;
        border-radius: 4px;
        outline: none;

        &:focus {
          box-shadow: var.$background-color-blue 0 0 2px 1px;
        }
      }

      &::after {
        content: 'maximum 10 character';
        position: absolute;
        top: -20px;
        right: 0;
        z-index: -1;
        width: max-content;
        height: 100%;
        font-size: 12px;
        text-align: right;
        // color: f;
        opacity: 0;
        animation: fadeOut 0.2s ease-in-out;
        transform: translateY(100%);
      }

      &:focus-within::after {
        z-index: 2;
        opacity: 1;
        animation: fadeIn 0.3s ease-in-out;
        transform: translateY(0%);
      }

      .orderRefMsg {
        position: absolute;
        top: 35px;
        right: 0;
        width: max-content;
        height: 20px;
        font-size: 12px;
        font-family: var.$font-family-primary-bold;
        color: red;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
