@use 'src/styles/main.scss' as main;

.card_list {
  display: grid;
  grid-template-rows: auto;
  grid-row: 1;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas: 'bloc1 bloc2 bloc3 bloc4 bloc5';
  gap: 24px;

  @media screen and (max-width: 1835px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: 'bloc1 bloc2 bloc3 bloc4';
    gap: 24px;
    > *:not(:nth-child(-n + 4)) {
      display: none;
    }
  }

  @media screen and (max-width: 1530px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'bloc1 bloc2 bloc3';
    gap: 24px;
    > *:not(:nth-child(-n + 3)) {
      display: none;
    }
  }

  @media screen and (max-width: 1230px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'bloc1 bloc2';
    gap: 20px;
    > *:not(:nth-child(-n + 2)) {
      display: none;
    }
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: 'bloc1';
    > *:not(:first-child) {
      display: none;
    }
  }
}
