@use 'src/styles/6-abstracts/variables' as var;
@use 'src/styles/2-components/cards' as cards;
@use 'src/styles/2-components/buttons' as buttons;

.img {
  border: 0;
}

// Define CSS variables for mui DataGrid Cell design
$statusBorderRadius: var.$border-radius-small;

$statusColorInflight: var.$color-in-flight;
$statusBgColorInflight: rgba(var.$color-in-flight, 0.1);

$statusColorScheduled: var.$color-scheduled;
$statusBgColorScheduled: rgba(var.$color-scheduled, 0.1);

$statusColorApproved: var.$color-approved;
$statusBgColorApproved: rgba(var.$color-approved, 0.1);

$statusColorReady: var.$color-ready;
$statusBgColorReady: rgba(var.$color-ready, 0.1);

$statusColorCancelled: var.$color-canceled;
$statusBgColorCancelled: rgba(var.$color-canceled, 0.1);

$statusColorCompleted: var.$color-completed;
$statusBgColorCompleted: rgba(var.$color-completed, 0.1);

$panelBorderColor: var.$border-color-primary;

$borderImage: 0px;

:export {
  statusBorderRadius: $statusBorderRadius;

  statusColorInflight: $statusColorInflight;
  statusBgColorInflight: $statusBgColorInflight;

  statusColorScheduled: $statusColorScheduled;
  statusBgColorScheduled: $statusBgColorScheduled;

  statusColorApproved: $statusColorApproved;
  statusBgColorApproved: $statusBgColorApproved;

  statusColorReady: $statusColorReady;
  statusBgColorReady: $statusBgColorReady;

  statusColorCancelled: $statusColorCancelled;
  statusBgColorCancelled: $statusBgColorCancelled;

  statusColorCompleted: $statusColorCompleted;
  statusBgColorCompleted: $statusBgColorCompleted;

  borderImage: $borderImage;
}