@use '../6-abstracts/variables' as var;

.auth {
  // width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(var.$background-color-blue, 0.9);

  .background {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .intro {
    width: 100%;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 80%;

      .title {
        width: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var.$font-family-primary-bold;
        font-size: var.$font-size-l;
        color: white;
        margin-top: -5px;
      }
  }

  .content_wrapper {
    z-index: 1;
    width: 430px;
    padding: 60px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white; 
    border-radius: var.$border-radius-large;
    box-shadow: 0 4px 4px rgba(0, 25, 75, 0.5);
    gap: 30px;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      // width: 100px;
      .logo_container {
        padding: 10px 0;
      }
      .title_login {
        width: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var.$font-family-primary-ultra-bold;
        font-size: 40px;
      }
      .subTitle {
        padding-bottom: 10px;
        font-family: var.$font-family-primary-bold;
        font-size: var.$font-size-m;
      }
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
      .login_button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 40px;
        border: 1px solid rgb(black, 0.1);
        border-radius: var.$border-radius-small;
        font-family: var.$font-family-primary-bold;

      }

      .signin_button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 40px;
        // border: 1px solid rgb(black, 0.1);
        color: white;
        border-radius: var.$border-radius-small;
        font-family: var.$font-family-primary-bold;
        background-color: var.$background-color-blue;

      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      
      .infoIcon{
        width: 17px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid black;
      }
      .infoText{
        font-family: var.$font-family-primary;
        font-size: var.$font-size-m;
      }
    }

    .progress_wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
