@use 'src/styles/6-abstracts/variables' as var;

.takeoffModal {
  max-width: 80%;
  max-height: 80%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background-color: var.$background-color-primary;
  border-width: 1px;
  border-style: solid;
  border-color: var.$border-color-primary;
  border-radius: var.$border-radius-large;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var.$font-family-primary;
    font-size: var.$font-size-3xl;
    color: green;
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-family: var.$font-family-primary;
    font-size: var.$font-size-l;
    text-align: center;
    color: green;

    #p {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: var.$font-family-primary;
    font-size: var.$font-size-m;
    text-align: center;
    color: var.$font-color-third;
  }
}
