@use 'src/styles/6-abstracts/variables' as var;

.errorModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 0 40px;
    width: 450px;
    height: 450px;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    background-color: var.$background-color-primary;
    border-radius: var.$border-radius-large;
    border-style: solid;
    border-width: 1px;
    border-color: var.$border-color-primary;

    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: var.$font-family-primary;
        font-size: var.$font-size-3xl;
        color: var.$font-color-third;
    }
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: var.$font-family-primary;
        font-size: var.$font-size-l;
        color: var.$font-color-fourth;

        #p { margin-bottom: 10px;}
    }
    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: var.$font-family-primary;
        font-size: var.$font-size-m;
        color: var.$font-color-third;
    }
}