@use 'src/styles/6-abstracts/variables' as var;

.reportIssueModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 40px 40px;
    width: 500px;
    background-color: var.$background-color-primary;
    border-radius: var.$border-radius-large;
    border-style: solid;
    border-width: 1px;
    border-color: var.$border-color-primary;
    font-family: var.$font-family-primary;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: var.$font-family-primary;
        font-size: var.$font-size-2xl;
        color: var.$font-color-third;
        text-align: center;
        gap: 10px;
    }
    .content {
        // font-size: var.$font-size-l;
        position: relative;
        .content_wrapper{
            display: flex;
            flex-direction: row;
            gap: 20px;
        }
        .list {
            list-style: none;
            padding: 0;
            overflow: hidden;
            transition: transform 0.5s ease;
        }

        #p { margin-bottom: 10px;}

        .selectedHub {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 65px;
            padding: 10px;
            gap: 10px;
            font-family: var.$font-family-primary;
            background-color: rgb(var.$background-color-blue, 10%);
            color: var.$color-blue;
            border-radius: var.$border-radius-medium;
            box-shadow: 2px 2px 10px rgb(black, 0.2);
        }

        .reportCard {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 65px;
            padding: 10px;
            gap: 10px;
            font-family: var.$font-family-primary;
            background-color: var.$background-color-primary;
            border-radius: var.$border-radius-medium;
            box-shadow: 2px 2px 10px rgb(black, 0.2);

            .card_name {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                // text-align: start;
            }

            &:hover {
                background-color: rgb(var.$background-color-blue, 10%);
                color: var.$color-blue;
                box-shadow: 2px 2px 10px rgb(black, 0.2);
                cursor: pointer;
            }
        }

        .slide_in {
            transform: translateX(0);
        }
          
        .slide_out {
            transform: translateX(-100%);
        }
    }
    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: var.$font-family-primary;
        font-size: var.$font-size-m;
        color: var.$font-color-third;
        gap: 20px;
    }
}

.reportIssueSuccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 60px 40px 40px 40px;
    width: 500px;
    background-color: var.$background-color-primary;
    border-radius: var.$border-radius-large;
    border-style: solid;
    border-width: 1px;
    border-color: var.$border-color-primary;
    font-family: var.$font-family-primary-bold;
    line-height: 20px;
    color: var.$color-success;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: var.$font-size-l;
        gap: 10px;
    }
}
.reportIssueError {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 60px 40px 40px 40px;
    width: 500px;
    background-color: var.$background-color-primary;
    border-radius: var.$border-radius-large;
    border-style: solid;
    border-width: 1px;
    border-color: var.$border-color-primary;
    font-family: var.$font-family-primary-bold;
    line-height: 20px;
    color: var.$color-error;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: var.$font-size-2xl;
        gap: 10px;
    }
}