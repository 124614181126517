@use 'src/styles/main.scss';
@use 'src/styles/6-abstracts/variables' as var;

.wrapper {
  width: 100%;
  // height: 100vh;
  min-height: 100vh;
  padding: 20px 37px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
  font-family: var.$font-family-primary;
  background-color: var.$background-color-third;
  backdrop-filter: blur(10px);
  color: var.$font-color-primary;
  cursor: default;

  .title {
    width: 100%;
    height: 60px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: var.$font-family-primary-bold;
    font-size: var.$font-size-xl;
    background-color: var.$background-color-primary;
    border-radius: var.$border-radius-large;
    border: 1px solid var.$border-color-primary;
    gap: 20px;

    .logo {
      width: 35px;
      height: 35px;
    }
  }

  .subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;

    .info {
      display: flex;
      flex-direction: row;
      gap: 30px;

      .item {
        display: flex;
        flex-direction: column;
        gap: 5px;
  
        .item_title {
          color: var.$font-color-third;
          font-size: var.$font-size-s;
        }
        .item_body {
          font-size: var.$font-size-l;
        }
      }
    }

    .menuBar {
      display: flex;
      flex-direction: row;
      gap: 30px;
    }

  }

  .checklist_wrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;
    
    .checklist_body {
      font-size: var.$font-size-page-title;
    }
  }

  // .body_wrapper{
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-items: center;
  //   gap: 20px;
  //   padding: 0px 0px;
  //   background-color: antiquewhite;

  //   .body_element {
  //     width: 200px;
  //     height: 200px;
  //     background-color: var.$background-color-blue;
  //     border-radius: var.$border-radius-large;
  //     cursor: pointer;
  //   }
  // }

  .takeoffButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: var.$font-size-xl;
    // padding: 20px 10px;
    // background-color: #ff8383;
    // border: 30px solid red;
    // color: #ff0000;
    // border-radius: 1000px;
    // width: 300px;
    // height: 300px;

    &:hover {
      background-color: red;
      color: white;
      cursor: pointer;
    }
  }


  // .tasks {
  //   display: flex;
  //   flex-direction: column;
  // }

  // .takeOffButton {
  //   width: fit-content;
  //   padding: 15px 30px;
  //   display: flex;
  //   gap: 10px;
  //   background-color: #ff8383;
  //   border: 0;
  //   border-radius: 10px;
  //   box-shadow: 4px 4px 4px 0 #00000040;
  //   font-size: 22px;
  //   cursor: pointer;
    
  //   &:hover {
  //     background-color: var.$background-color-blue;
  //     color: white;
  //   }
  // }

  // .takeOffButton:hover {
  //   background-color: #ff0000;
  // }

  @media screen and (max-width: 1000px) {
    height: 110vh;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    // gap: 10px;
    // margin-bottom: 40px;
    // font-size: 30px; 

    .logo {
      width: 30px;
      height: 30px;
    }
  }

  .subtitle {


    .info {
      flex-direction: column;
      gap: 10px;

      .item {

  
        .item_title {

        }
        .item_body {
        }
      }
    }


  }
  }
}
