@use 'src/styles/6-abstracts/variables' as var;
@use 'src/styles/2-components/cards' as cards;

.basicTable {
  .body_wrapper {
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow: auto;
    white-space: nowrap;
  }
}

// Define CSS variables for mui DataGrid Cell design
$statusBorderRadius: var.$border-radius-small;

$statusColorPending: var.$color-pending;
$statusBgColorPending: rgba(var.$color-pending, 0.1);

$statusColorScheduled: var.$color-scheduled;
$statusBgColorScheduled: rgba(var.$color-scheduled, 0.1);

$statusColorApproved: var.$color-approved;
$statusBgColorApproved: rgba(var.$color-approved, 0.1);

$statusColorComing: var.$color-coming;
$statusBgColorComing: rgba(var.$color-coming, 0.1);

$statusColorReady: var.$color-ready;
$statusBgColorReady: rgba(var.$color-ready, 0.1);

$statusColorLoading: var.$color-loading;
$statusBgColorLoading: rgba(var.$color-loading, 0.1);

$statusColorLoaded: var.$color-loaded;
$statusBgColorLoaded: rgba(var.$color-loaded, 0.1);

$statusColorInflight: var.$color-in-flight;
$statusBgColorInflight: rgba(var.$color-in-flight, 0.1);

$statusColorReadyToUnload: var.$color-ready-to-unload;
$statusBgColorReadyToUnload: rgba(var.$color-ready-to-unload, 0.1);

$statusColorUnloading: var.$color-unloading;
$statusBgColorUnloading: rgba(var.$color-unloading, 0.1);

$statusColorCompleted: var.$color-completed;
$statusBgColorCompleted: rgba(var.$color-completed, 0.1);

$statusColorCancelled: var.$color-canceled;
$statusBgColorCancelled: rgba(var.$color-canceled, 0.1);

$statusColorAborted: var.$color-aborted;
$statusBgColorAborted: rgba(var.$color-aborted, 0.1);

$panelBorderColor: var.$border-color-primary;

:export {
  statusBorderRadius: $statusBorderRadius;

  statusColorPending: $statusColorPending;
  statusBgColorPending: $statusBgColorPending;

  statusColorScheduled: $statusColorScheduled;
  statusBgColorScheduled: $statusBgColorScheduled;

  statusColorApproved: $statusColorApproved;
  statusBgColorApproved: $statusBgColorApproved;

  statusColorComing: $statusColorComing;
  statusBgColorComing: $statusBgColorComing;

  statusColorReady: $statusColorReady;
  statusBgColorReady: $statusBgColorReady;

  statusColorLoading: $statusColorLoading;
  statusBgColorLoading: $statusBgColorLoading;

  statusColorLoaded: $statusColorLoaded;
  statusBgColorLoaded: $statusBgColorLoaded;

  statusColorInflight: $statusColorInflight;
  statusBgColorInflight: $statusBgColorInflight;

  statusColorReadyToUnload: $statusColorReadyToUnload;
  statusBgColorReadyToUnload: $statusBgColorReadyToUnload;

  statusColorUnloading: $statusColorUnloading;
  statusBgColorUnloading: $statusBgColorUnloading;

  statusColorCancelled: $statusColorCancelled;
  statusBgColorCancelled: $statusBgColorCancelled;

  statusColorCompleted: $statusColorCompleted;
  statusBgColorCompleted: $statusBgColorCompleted;

  statusColorAborted: $statusColorAborted;
  statusBgColorAborted: $statusBgColorAborted;
}