@use '../6-abstracts/variables' as var;

.body {
  width: 100%;
  max-width: 2000px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: minmax(300px, auto);
  grid-template-rows: minmax(250px,auto) auto auto auto;
  grid-template-areas:
    'bloc2'
    'bloc1'
    'bloc4'
    'bloc3';
  gap: 24px;
  z-index: 1;
  #bloc1 {
    width: 100%;
    grid-area: bloc1;
  }

  #bloc2 {
    width: 100%;
    grid-area: bloc2;
  }

  #bloc3 {
    width: 100%;
    grid-area: bloc3;
  }

  #bloc4 {
    width: 100%;
    grid-area: bloc4;
  }

  @media (min-width: 1200px) {
    grid-template-columns: minmax(300px, auto) 0.2fr 1fr 1fr minmax(225px, auto) minmax(225px, auto);
    grid-template-rows: auto auto;
    grid-template-areas:
      'bloc1 bloc1 bloc1 bloc1 bloc2 bloc2'
      'bloc4 bloc4 bloc3 bloc3 bloc3 bloc3';
  }
}

.progress_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
