@use 'src/styles/6-abstracts/variables' as var;
@use 'src/styles/2-components/cards' as cards;
@use 'src/styles/2-components/buttons' as buttons;

// Define CSS variables for mui DataGrid Cell design
$statusBorderRadius: var.$border-radius-small;

$statusColorPending: var.$color-pending;
$statusBgColorPending: rgba(var.$color-pending, 0.1);

$statusColorScheduled: var.$color-scheduled;
$statusBgColorScheduled: rgba(var.$color-scheduled, 0.1);

$statusColorApproved: var.$color-approved;
$statusBgColorApproved: rgba(var.$color-approved, 0.1);

$statusColorComing: var.$color-coming;
$statusBgColorComing: rgba(var.$color-coming, 0.1);

$statusColorReady: var.$color-ready;
$statusBgColorReady: rgba(var.$color-ready, 0.1);

$statusColorLoading: var.$color-loading;
$statusBgColorLoading: rgba(var.$color-loading, 0.1);

$statusColorLoaded: var.$color-loaded;
$statusBgColorLoaded: rgba(var.$color-loaded, 0.1);

$statusColorInflight: var.$color-in-flight;
$statusBgColorInflight: rgba(var.$color-in-flight, 0.1);

$statusColorReadyToUnload: var.$color-ready-to-unload;
$statusBgColorReadyToUnload: rgba(var.$color-ready-to-unload, 0.1);

$statusColorUnloading: var.$color-unloading;
$statusBgColorUnloading: rgba(var.$color-unloading, 0.1);

$statusColorCompleted: var.$color-completed;
$statusBgColorCompleted: rgba(var.$color-completed, 0.1);

$statusColorCancelled: var.$color-canceled;
$statusBgColorCancelled: rgba(var.$color-canceled, 0.1);

$statusColorAborted: var.$color-aborted;
$statusBgColorAborted: rgba(var.$color-aborted, 0.1);

$panelBorderColor: var.$border-color-primary;

:export {
  statusBorderRadius: $statusBorderRadius;

  statusColorPending: $statusColorPending;
  statusBgColorPending: $statusBgColorPending;

  statusColorScheduled: $statusColorScheduled;
  statusBgColorScheduled: $statusBgColorScheduled;

  statusColorApproved: $statusColorApproved;
  statusBgColorApproved: $statusBgColorApproved;

  statusColorComing: $statusColorComing;
  statusBgColorComing: $statusBgColorComing;

  statusColorReady: $statusColorReady;
  statusBgColorReady: $statusBgColorReady;

  statusColorLoading: $statusColorLoading;
  statusBgColorLoading: $statusBgColorLoading;

  statusColorLoaded: $statusColorLoaded;
  statusBgColorLoaded: $statusBgColorLoaded;

  statusColorInflight: $statusColorInflight;
  statusBgColorInflight: $statusBgColorInflight;

  statusColorReadyToUnload: $statusColorReadyToUnload;
  statusBgColorReadyToUnload: $statusBgColorReadyToUnload;

  statusColorUnloading: $statusColorUnloading;
  statusBgColorUnloading: $statusBgColorUnloading;

  statusColorCancelled: $statusColorCancelled;
  statusBgColorCancelled: $statusBgColorCancelled;

  statusColorCompleted: $statusColorCompleted;
  statusBgColorCompleted: $statusBgColorCompleted;

  statusColorAborted: $statusColorAborted;
  statusBgColorAborted: $statusBgColorAborted;
}

.statusCell {
  position: relative;
  overflow: visible;
  width: 100%;

  .hmiButton {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 45px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var.$background-color-blue;
    border-radius: var.$border-radius-medium;
    transform: translateY(-50%) translateX(100%);
    transition: transform 0.3s ease-in-out;
    font-size: var.$font-size-m;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;

    .contentButton{
      display: flex;
      flex-direction: row;
      color: white;
      gap: 10px;
    }
  }

  .hmiButton.activeRow {
    transform: translateY(-50%) translateX(-40%);
    transition: transform 0.3s ease-in-out;
  }
}

.shrink_btn {
  display: flex;
  gap: 10px;
}

.extended_btn {
  cursor: pointer;
}

.exportButton {
  background-color: unset;
  color: black;
  &:hover {
    background-color: rgb(var.$background-color-blue, 10%);
    color: var.$color-blue;
  }
}