@use '../../../styles/6-abstracts/variables' as var;

.drop-down-menu {
  position: absolute;
  background-color: var.$background-color-primary;
  border-radius: var.$border-radius-large;
  box-shadow: 0px 2px 5px
  rgba(
    $color: #000000,
    $alpha: 0.4,
  );
  width: 200px;
  top: 70px;
  right: 0px;
  overflow: hidden;
  font-family: var.$font-family-primary-bold;
    
  .menu-item {
    padding: 15px 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: var.$background-color-third;
    }
  }
  
  .solid-separator {
    border-top: 1px solid var.$border-color-primary;
    margin: 0;
  }
}
