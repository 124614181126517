@use '../6-abstracts/variables' as var;

.body {
  width: 100%;
  max-width: 2000px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'bloc1 bloc1 bloc1 bloc1'
    'bloc2 bloc2 bloc2 bloc3';

  gap: 24px 0;
  z-index: 1;

  #bloc1 {
    width: 100%;
    z-index: 2;
    grid-area: bloc1;
  }

  #bloc2 {
    width: 100%;
    z-index: 1;
    grid-area: bloc2;
  }
}
