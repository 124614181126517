@use 'src/styles/6-abstracts/variables' as var;

.pinnedHubCard {
  height: 150px;
  display: flex;
  gap: 10px;
  overflow: hidden;
  background: var.$background-color-primary;
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  font-family: var.$font-family-primary;

  .hubGeneralInfo {
    position: relative;
    width: 45%;//220px;
    overflow: hidden;
    background-color: black;

    .coverImg {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      opacity: 90%;
      inset: 0;
      object-fit: cover;
    }

    .generalInfo {
      position: relative;
      z-index: 1;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 10px;
      font-family: var.$font-family-primary-bold;
      font-size: 16px;
      color: var.$font-color-secondary;
      text-shadow: 0 0 2px var.$background-color-secondary;

      .hubStatus {
        width: 15px;
        height: 15px;
        background-color: currentColor;
        box-shadow: 0 0 2px var.$background-color-secondary;
        border-radius: 50%;
      }
    }
  }

  .compactHubOperationInfo {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    .pillContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      .statusPill {
        margin: auto;
        display: flex;
        border-radius: 8px;

        .statusName {
          width: 70px;
          padding: 10px 5px 10px 10px;
          color: white;
        }

        .statusNum {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 30px;
          font-family: var.$font-family-primary-bold;
          color: currentColor;
          box-shadow: 2px 2px 2px 0px #00000040;
          border-radius: 8px;
        }
      }
    }
  }

  .iconBar {
    padding: 15px 15px 15px 0;

    .pinIcon {
      cursor: pointer;
      width: 20px;
      height: 20px;

      &:hover {
        color: var.$color-blue;
      }
    }
  }


  @media (min-width: 776px) and (max-width: 1200px) {
    height: 75px;

    .hubGeneralInfo {
      min-width: 220px;
      .coverImg {
        left: -50%;
        width: 200%;
      }
    }
    .compactHubOperationInfo {
      min-width: 450px;
      padding-left: 20px;
      padding-right: 10px;
      .pillContainer {
        gap: 10px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
    .iconBar {
      min-width: 40px;
      padding: 10px 10px 10px 10px;
      display: flex;
      align-items: center;
    }
  }

  @media (min-width: 631px) and (max-width: 775px) {
    height: max-content;
    flex-direction: column;
    align-items: flex-start;

    .hubGeneralInfo {
      width: 100%;
      height: 75px;
    }
    .compactHubOperationInfo {
      width: 100%;
      padding-bottom: 5px;
      .pillContainer {
        grid-template-columns:  1fr 1fr;
        gap: 10px;
        .statusPill{gap: 30px;}
      }
    }
    .iconBar {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {background-color: var.$background-color-third;}
    }
  }

  @media screen and (max-width: 630px) {
    height: max-content;
    flex-direction: column;
    align-items: flex-start;

    .hubGeneralInfo {
      width: 100%;
      height: 75px;
    }
    .compactHubOperationInfo {
      width: 100%;
      padding-bottom: 5px;
      .pillContainer {
        grid-template-columns:  1fr;
        gap: 10px;
        .statusPill{gap: 30px;}
      }
    }
    .iconBar {
      width: 100%;
      padding: 10px 10px 10px 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {background-color: var.$background-color-third;}
    }
  }


}

$readyColor: var.$color-action-ready;
$readyBgColor: rgb($readyColor, 10%);
$ongoingColor: var.$color-action-ongoing;
$ongoingBgColor: rgb($ongoingColor, 10%);
$greenStatus: var.$color-status-green;
$orangeStatus: var.$color-status-orange;
$redStatus: var.$color-status-red;
$boldFontFamily: var.$font-family-primary-bold;
$defaultFontFamily: var.$font-family-primary;

:export {
  readyColor: $readyColor;
  readyBgColor: $readyBgColor;
  ongoingColor: $ongoingColor;
  ongoingBgColor: $ongoingBgColor;

  boldFontFamily: $boldFontFamily;
  defaultFontFamily: $defaultFontFamily;

  greenStatus: $greenStatus;
  orangeStatus: $orangeStatus;
  redStatus: $redStatus;


}
