
// Font-family

/* 
    Basier Circle 
*/
@font-face {
    font-family: 'Basier Circle';
    src: url('/fonts/BasierCircle/BasierCircle-BoldItalic.eot');
    src: local('Basier Circle Bold Italic'), local('BasierCircle-BoldItalic'),
        url('/fonts/BasierCircle/BasierCircle-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BasierCircle/BasierCircle-BoldItalic.woff2') format('woff2'),
        url('/fonts/BasierCircle/BasierCircle-BoldItalic.woff') format('woff'),
        url('/fonts/BasierCircle/BasierCircle-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Basier Circle';
    src: url('/fonts/BasierCircle/BasierCircle-Bold.eot');
    src: local('Basier Circle Bold'), local('BasierCircle-Bold'),
        url('/fonts/BasierCircle/BasierCircle-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BasierCircle/BasierCircle-Bold.woff2') format('woff2'),
        url('/fonts/BasierCircle/BasierCircle-Bold.woff') format('woff'),
        url('/fonts/BasierCircle/BasierCircle-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Basier Circle';
    src: url('/fonts/BasierCircle/BasierCircle-MediumItalic.eot');
    src: local('Basier Circle Medium Itali'), local('BasierCircle-MediumItalic'),
        url('/fonts/BasierCircle/BasierCircle-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BasierCircle/BasierCircle-MediumItalic.woff2') format('woff2'),
        url('/fonts/BasierCircle/BasierCircle-MediumItalic.woff') format('woff'),
        url('/fonts/BasierCircle/BasierCircle-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Basier Square';
    src: url('/fonts/BasierCircle/BasierSquare-SemiBoldItalic.eot');
    src: local('Basier Square SemiBold Ita'), local('BasierSquare-SemiBoldItalic'),
        url('/fonts/BasierCircle/BasierSquare-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BasierCircle/BasierSquare-SemiBoldItalic.woff2') format('woff2'),
        url('/fonts/BasierCircle/BasierSquare-SemiBoldItalic.woff') format('woff'),
        url('/fonts/BasierCircle/BasierSquare-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Basier Circle';
    src: url('/fonts/BasierCircle/BasierCircle-Italic.eot');
    src: local('Basier Circle Italic'), local('BasierCircle-Italic'),
        url('/fonts/BasierCircle/BasierCircle-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BasierCircle/BasierCircle-Italic.woff2') format('woff2'),
        url('/fonts/BasierCircle/BasierCircle-Italic.woff') format('woff'),
        url('/fonts/BasierCircle/BasierCircle-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Basier Circle';
    src: url('/fonts/BasierCircle/BasierCircle-SemiBold.eot');
    src: local('Basier Circle SemiBold'), local('BasierCircle-SemiBold'),
        url('/fonts/BasierCircle/BasierCircle-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BasierCircle/BasierCircle-SemiBold.woff2') format('woff2'),
        url('/fonts/BasierCircle/BasierCircle-SemiBold.woff') format('woff'),
        url('/fonts/BasierCircle/BasierCircle-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Basier Circle';
    src: url('/fonts/BasierCircle/BasierCircle-Medium.eot');
    src: local('Basier Circle Medium'), local('BasierCircle-Medium'),
        url('/fonts/BasierCircle/BasierCircle-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BasierCircle/BasierCircle-Medium.woff2') format('woff2'),
        url('/fonts/BasierCircle/BasierCircle-Medium.woff') format('woff'),
        url('/fonts/BasierCircle/BasierCircle-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Basier Circle';
    src: url('/fonts/BasierCircle/BasierCircle-Medium.eot');
    src: local('Basier Circle Medium'), local('BasierCircle-Medium'),
        url('/fonts/BasierCircle/BasierCircle-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BasierCircle/BasierCircle-Medium.woff2') format('woff2'),
        url('/fonts/BasierCircle/BasierCircle-Medium.woff') format('woff'),
        url('/fonts/BasierCircle/BasierCircle-Medium.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Basier Circle';
    src: url('/fonts/BasierCircle/BasierCircle-Regular.eot');
    src: local('Basier Circle Regular'), local('BasierCircle-Regular'),
        url('/fonts/BasierCircle/BasierCircle-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BasierCircle/BasierCircle-Regular.woff2') format('woff2'),
        url('/fonts/BasierCircle/BasierCircle-Regular.woff') format('woff'),
        url('/fonts/BasierCircle/BasierCircle-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Basier Circle';
    src: url('/fonts/BasierCircle/BasierCircle-SemiBoldItalic.eot');
    src: local('Basier Circle SemiBold Ita'), local('BasierCircle-SemiBoldItalic'),
        url('/fonts/BasierCircle/BasierCircle-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BasierCircle/BasierCircle-SemiBoldItalic.woff2') format('woff2'),
        url('/fonts/BasierCircle/BasierCircle-SemiBoldItalic.woff') format('woff'),
        url('/fonts/BasierCircle/BasierCircle-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}





/* 
    Museo Sans 
*/
@font-face {
    font-family: 'MuseoSansCyrl-100';
    src: url('/fonts/MuseoSans/MuseoSansCyrl-100.eot');
    src: local('☞MuseoSansCyrl-100'), local('MuseoSansCyrl-100'),
        url('/fonts/MuseoSans/MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
        url('/fonts/MuseoSans/MuseoSansCyrl-100.woff2') format('woff2'),
        url('/fonts/MuseoSans/MuseoSansCyrl-100.woff') format('woff'),
        url('/fonts/MuseoSans/MuseoSansCyrl-100.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSansCyrl-100Italic';
    src: url('/fonts/MuseoSans/MuseoSansCyrl-100Italic.eot');
    src: local('☞MuseoSansCyrl-100Italic'), local('MuseoSansCyrl-100Italic'),
        url('/fonts/MuseoSans/MuseoSansCyrl-100Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/MuseoSans/MuseoSansCyrl-100Italic.woff2') format('woff2'),
        url('/fonts/MuseoSans/MuseoSansCyrl-100Italic.woff') format('woff'),
        url('/fonts/MuseoSans/MuseoSansCyrl-100Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'MuseoSansCyrl-300';
    src: url('/fonts/MuseoSans/MuseoSansCyrl-300.eot');
    src: local('☞MuseoSansCyrl-300'), local('MuseoSansCyrl-300'),
        url('/fonts/MuseoSans/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
        url('/fonts/MuseoSans/MuseoSansCyrl-300.woff2') format('woff2'),
        url('/fonts/MuseoSans/MuseoSansCyrl-300.woff') format('woff'),
        url('/fonts/MuseoSans/MuseoSansCyrl-300.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSansCyrl-300Italic';
    src: url('/fonts/MuseoSans/MuseoSansCyrl-300Italic.eot');
    src: local('☞MuseoSansCyrl-300Italic'), local('MuseoSansCyrl-300Italic'),
        url('/fonts/MuseoSans/MuseoSansCyrl-300Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/MuseoSans/MuseoSansCyrl-300Italic.woff2') format('woff2'),
        url('/fonts/MuseoSans/MuseoSansCyrl-300Italic.woff') format('woff'),
        url('/fonts/MuseoSans/MuseoSansCyrl-300Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'MuseoSansCyrl-500';
    src: url('/fonts/MuseoSans/MuseoSansCyrl-500.eot');
    src: local('☞MuseoSansCyrl-500'), local('MuseoSansCyrl-500'),
        url('/fonts/MuseoSans/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
        url('/fonts/MuseoSans/MuseoSansCyrl-500.woff2') format('woff2'),
        url('/fonts/MuseoSans/MuseoSansCyrl-500.woff') format('woff'),
        url('/fonts/MuseoSans/MuseoSansCyrl-500.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSansCyrl-500Italic';
    src: url('/fonts/MuseoSans/MuseoSansCyrl-500Italic.eot');
    src: local('☞MuseoSansCyrl-500Italic'), local('MuseoSansCyrl-500Italic'),
        url('/fonts/MuseoSans/MuseoSansCyrl-500Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/MuseoSans/MuseoSansCyrl-500Italic.woff2') format('woff2'),
        url('/fonts/MuseoSans/MuseoSansCyrl-500Italic.woff') format('woff'),
        url('/fonts/MuseoSans/MuseoSansCyrl-500Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'MuseoSansCyrl-700';
    src: url('/fonts/MuseoSans/MuseoSansCyrl-700.eot');
    src: local('☞MuseoSansCyrl-700'), local('MuseoSansCyrl-700'),
        url('/fonts/MuseoSans/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
        url('/fonts/MuseoSans/MuseoSansCyrl-700.woff2') format('woff2'),
        url('/fonts/MuseoSans/MuseoSansCyrl-700.woff') format('woff'),
        url('/fonts/MuseoSans/MuseoSansCyrl-700.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSansCyrl-700Italic';
    src: url('/fonts/MuseoSans/MuseoSansCyrl-700Italic.eot');
    src: local('☞MuseoSansCyrl-700Italic'), local('MuseoSansCyrl-700Italic'),
        url('/fonts/MuseoSans/MuseoSansCyrl-700Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/MuseoSans/MuseoSansCyrl-700Italic.woff2') format('woff2'),
        url('/fonts/MuseoSans/MuseoSansCyrl-700Italic.woff') format('woff'),
        url('/fonts/MuseoSans/MuseoSansCyrl-700Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'MuseoSansCyrl-900';
    src: url('/fonts/MuseoSans/MuseoSansCyrl-900.eot');
    src: local('☞MuseoSansCyrl-900'), local('MuseoSansCyrl-900'),
        url('/fonts/MuseoSans/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
        url('/fonts/MuseoSans/MuseoSansCyrl-900.woff2') format('woff2'),
        url('/fonts/MuseoSans/MuseoSansCyrl-900.woff') format('woff'),
        url('/fonts/MuseoSans/MuseoSansCyrl-900.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSansCyrl-900Italic';
    src: url('/fonts/MuseoSans/MuseoSansCyrl-900Italic.eot');
    src: local('☞MuseoSansCyrl-900Italic'), local('MuseoSansCyrl-900Italic'),
        url('/fonts/MuseoSans/MuseoSansCyrl-900Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/MuseoSans/MuseoSansCyrl-900Italic.woff2') format('woff2'),
        url('/fonts/MuseoSans/MuseoSansCyrl-900Italic.woff') format('woff'),
        url('/fonts/MuseoSans/MuseoSansCyrl-900Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

