@use 'src/styles/2-components/modal';
@use 'src/styles/2-components/buttons';
@use 'src/styles/6-abstracts/variables' as var;

.errMsg{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var.$color-canceled;
}

.positiveResult {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    line-height: 100%;
    width: 100%;
    margin-bottom: 10px;
    gap: 20px;
    color: green;
    font-size: var.$font-size-xl;

    // marginBottom: '20px',
    // display: 'flex',
    // justifyContent: 'left',
    // alignItems: 'left',
    // gap: '20px',
    // fontSize: '1.5rem',
    // fontWeight: 'bold',
    // textAlign: 'center',
    // color: 'green',
}