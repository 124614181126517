@use 'src/styles/6-abstracts/variables' as var;

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    max-width: 100%;
    height: 400px;
    padding: 30px 20px 20px 20px;
    background-color: var.$background-color-primary;
    border-radius: var.$border-radius-large;
    color: var.$font-color-primary;

    @media screen and (max-width: 1050px) {
      width: 75vw;
      height: 180px;
      padding: 20px 20px;
    }
  }

  .card_current {
    background-color: var.$background-color-blue;
    color: var.$font-color-secondary;
  }
  .card_next {
    background-color: rgb(var.$background-color-primary, 80%);
  }
  .card_previous {
    background-color: var.$background-color-blue;
    color: var.$font-color-secondary;
    opacity: 60%;
  }
  
  .element_header {
    display: flex;
    flex-direction: row;
    align-items: flex-start; justify-content: flex-start;
    height: 10px;
    font-size: var.$font-size-xl;
    .logo{
      position: absolute;
      top: -20px;
    }
  }

  .element_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .progress {
      height: 50px;
      width: 50px;
    }
  }
  .card img {
    margin-top: -20%;
    width: 100%;
    height: 100px;
    border-radius: 20px;
  }
  
  .card h2 {
    margin: 0;
    margin-top: 1rem;
  }
  
  .card p {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .card .btnn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  