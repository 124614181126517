@use 'src/styles/main.scss' as main;

.comingdeliveries {
  .body_wrapper {
    padding-bottom: 15px;
    padding-right: 1px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 20px;

    .empty_result {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 0px;
      justify-content: center;
      align-items: center;
    }
  }
}
