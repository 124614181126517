@use '../6-abstracts/variables' as var;

.body {
  height: 100%;
  max-width: 2000px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  // grid-template-columns: 1fr;
  // grid-template-rows: auto auto auto;
  // grid-template-areas:
  // "bloc1"
  // "bloc2"
  // "bloc3";
  gap: 24px;
  z-index: 1;

  #bloc1 {
    width: 100%;
    // grid-area: bloc1;
  }

  #bloc2 {
    width: 100%;
    // grid-area: bloc2;
  }

  #bloc3 {
    width: 100%;
    // grid-area: bloc3;
  }
}
