@use 'src/styles/6-abstracts/variables' as var;

.digital_clock_wrapper {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  background: rgb(var.$background-color-blue, 10%);
  border-radius: 6px;
  color: var.$background-color-blue;

  .clock_title {
    display: flex;
    gap: 5px;
    font-size: 15px;
  }

  .clock_time {
    font-size: 20px;
  }

}