// @use 'src/styles/main.scss' as main;
@use 'src/styles/6-abstracts/variables' as var;
@use 'src/styles/2-components/cards' as card;

.landed {
    // padding: 4px 10px;
    font-size: var.$font-size-m;
    color: var.$color-ready-to-unload;
    // background-color: rgb(var.$color-ready-to-unload, 10%);
}
