@use 'src/styles/main.scss' as main;

.status_summary {
  .body_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .statusbar {
      width: 100%;
      height: 55%;
      padding: 10px 0;
      gap: 30px;

      .statusbar_body {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        font-size: main.$font-size-s;

        @media screen and (max-width: 520px) {
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
        }

        // .body_col {
        //   display: flex;
        //   flex-direction: column;
        //   gap: 10px;

        .status {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;

          .occurrence {
            width: 25px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: main.$border-radius-medium;
            font-family: main.$font-family-primary-bold;
            color: main.$font-color-secondary;
          }

          .pending {
            background-color: main.$color-pending;
          }

          .scheduled {
            background-color: main.$color-scheduled;
          }

          .loading {
            background-color: main.$color-loading;
          }

          .inFlight {
            background-color: main.$color-in-flight;
          }

          .unloading {
            background-color: main.$color-ready-to-unload;
          }

          .canceled {
            background-color: main.$color-canceled;
          }

          .completed {
            background-color: main.$color-completed;
          }
        }
      }

      // }
    }

    .genInfo {
      display: flex;
      flex-direction: row;
      gap: 10px;
      font-family: main.$font-family-primary-bold;

      .profile {
        width: 30%;
        height: 50px;
      }

      .time {
        width: 30%;
        height: 50px;
        gap: 15px;
      }

      .date {
        width: 40%;
        height: 50px;
        gap: 15px;

        .date_body {
          display: flex;
          flex-direction: column;
          font-size: main.$font-size-s;
          gap: 2px;
        }
      }
    }
  }
}
