@use 'src/styles/6-abstracts/variables' as var;

.takeOffButton {
        // position: absolute;
        // left: 45%;
        // top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 150px;
        width: 150px;
        border-radius: 1000px;
        background-color: rgb(190, 28, 28);
        cursor: pointer;

      .text_wrapper {
        width: 100px;
        height: 100px;
        shape-outside: circle();
        float: left; /* Allows text to wrap around the shape */

      }
  }

  .takeOffButton:hover {
    background-color: #ff0000;
  }