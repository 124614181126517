@use '../6-abstracts/variables' as var;

.topbar {
  z-index: 1000;
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  backdrop-filter: blur(10px);
  border-style: solid;
  border-width: 1px;
  border-color: var.$border-color-primary;
  border-radius: var.$border-radius-large;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    font-family: var.$font-family-primary-bold;
    font-size: var.$font-size-xl;

    .mobileMenu {
      display: none;
    }
    .mobileClock {
      display: none;
    }
  }

  .content_wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  @media (max-width: 1000px) {
      .title{
        .mobileMenu {
          display: block;
        }
        .mobileClock{
          display: block;
        }
      }
    }
  
}
