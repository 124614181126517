@use 'src/styles/6-abstracts/variables' as var;

.card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    width: max-content;
    // height: 60vh;
    // padding: 4rem 2rem 2rem 2rem;
    border-radius: 10px;
    color: var.$font-color-primary;
    overflow: auto;

    .header {
      padding: 2rem 2rem 0rem 2rem;
    }

    .content {
      padding: 0rem 2rem 0rem 2rem;
    }

    .footer {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .img {
        width: 250px;
        border-radius: 0px 100px 0px 0px;
      }
      .btnn {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        padding: 0rem 2rem 2rem 2rem;
      }
    }

  }

  .list1{
    color: var.$color-blue;
    background-color: white;// rgb(199, 223, 255); // rgb(89, 0, 255);
  }
  .list2{
    color: var.$color-orange;
    background-color: white; // rgb(255, 243, 231); // rgb(89, 0, 255);
  }
  .list3{
    color: var.$color-blue;
    background-color: white;// rgb(199, 223, 255); // rgb(89, 0, 255);
  }
  .list4{
    color: var.$color-orange;
    background-color: white;// rgb(255, 243, 231); // rgb(89, 0, 255);
  }

  @media (max-width: 950px) {
    .card {
      // width: 90vw;
    }
  }
  