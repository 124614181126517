@use 'src/styles/main.scss' as main;

.newOrder {
  // height: 330px;
  width: 100%;

  // margin-bottom: ;
  .body_wrapper {
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 30px;

    .hub_picker {
      max-width: 690px;
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      gap: 30px;
    }
  }

  @media (min-width: 850px) {
    .body_wrapper {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
}
