@use 'src/styles/main.scss' as main;

.goodsList {
  .body_wrapper {
    padding: 15px 15px 15px 0;
    display: flex;
    overflow: clip;
    gap: 20px;
    overflow: auto;
    white-space: nowrap;
    animation: all 0.3s;
  }

  &.disabled {
    filter: brightness(90%);

    .body_wrapper {
      > * {
        pointer-events: none;
      }
    }
  }
}

.editButton {
  position: absolute;
  right: 85px;
  top: 50px;
}
