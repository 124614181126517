@use 'src/styles/6-abstracts/variables' as var;

.digital_clock_wrapper {
  padding: 20px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 7px;
  background: rgb(var.$background-color-blue, 10%);
  border-radius: 12px;
  color: var.$background-color-blue;

  .clock_title {
    display: flex;
    gap: 5px;
    font-size: 10px;
  }

  .clock_time {
    font-size: 30px;
  }

  @media (max-width: 769px) {
    padding: 12px 8px;

    .clock_time {
      word-break: break-all;
    }
  }
}