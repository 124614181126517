@use '../6-abstracts/variables' as var;

.main_layout {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var.$background-color-third;

  .content_wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: clip;

    .header {
      position: sticky;
      top: 0;
      z-index: 2;
      padding: 20px 20px;
      background-image: linear-gradient(
        to bottom,
        rgb(255, 255, 255) 0%,
        transparent 90%
      );
    }

    .body {
      position: relative;
      z-index: 1;
      height: 100%;
      padding: 20px;
      overflow: auto;
    }
  }

  @media (min-width: 1025px) {
    .content_wrapper {
      .header {
        padding: 20px 40px;
      }

      .body {
        padding: 10px 40px;
      }
    }
  }
}
