@use 'src/styles/6-abstracts/variables' as var;

.listWrapper {
    display: flex;
    flex-direction: column;
}

.listItem {
    display: flex;
    flex-direction: row;
}

.productWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
    padding: 10px 20px;
    background-color: rgb(var.$background-color-blue, 10%);
    border-radius: 5px;
    color: var.$color-blue;
    gap: 5px;

    .productTitle_wrapper {
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .editButton {
            display: flex;
            flex-direction: row; align-items: center; justify-content: center;
            height: 25px; width: 25px;
            border-radius: 50%;
            margin-right: -8px;

            &:hover{
                background-color: white;
                cursor: pointer;
            }
        }
    }
    .productTitle {
        font-family: var.$font-family-primary-ultra-bold;
    }

    .productItem {
        display: flex;
        flex-direction: row;
        gap: 10px;
        font-style: italic;
    }
    .containerItem {
        font-family: var.$font-family-primary-italic;
    }
}

.batteryWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
    padding: 10px 20px;
    background-color: rgb(var.$background-color-blue, 10%);
    border-radius: 5px;
    color: var.$color-blue;
    gap: 5px;

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 10px;
        font-family: var.$font-family-primary-ultra-bold;
    }
}

.weightWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
    padding: 10px 20px;
    background-color: rgb(var.$background-color-blue, 10%);
    border-radius: 5px;
    color: var.$color-blue;
    gap: 5px;

    .weightTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        font-family: var.$font-family-primary-ultra-bold;
    }
}

.insertionWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
    padding: 10px 20px;
    background-color: rgb(var.$background-color-blue, 10%);
    border-radius: 5px;
    color: var.$color-blue;
    gap: 5px;

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .insertionTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        font-family: var.$font-family-primary-ultra-bold;
    }

    .imageWrapper {
        .img { width: 500px; }
        .bottle {
            position: absolute; 
            width: 24px;
            height: 24px;
            border-radius: 50%;
            &:hover { 
                cursor: pointer;
            }
        }
        .bottleA2 { top: 102.5px; left: 177.5px; }
        .bottleA5 { top: 102.5px; left: 213px; }
        .bottleA8 { top: 102.5px; left: 249px; }
        .bottleA3 { top: 126.5px; left: 197px; }
        .bottleA6 { top: 126.5px; left: 229.5px; }
        .bottleA1 { top: 150px; left: 177.5px; }
        .bottleA4 { top: 150px; left: 213px; }
        .bottleA7 { top: 150px; left: 249px; }

        .bottleB2 { top: 102.5px; left: 309px; }
        .bottleB5 { top: 102.5px; left: 344px; }
        .bottleB8 { top: 102.5px; left: 380px; }
        .bottleB3 { top: 126.5px; left: 328px; }
        .bottleB6 { top: 126.5px; left: 360.5px; }
        .bottleB1 { top: 150px; left: 309px; }
        .bottleB4 { top: 150px; left: 344px; }
        .bottleB7 { top: 150px; left: 380px; }

        .bottleA2_right { top: 150px; left: 361px; }
        .bottleA5_right { top: 150px; left: 325px; }
        .bottleA8_right { top: 150px; left: 289.5px; }
        .bottleA3_right { top: 126.5px; left: 341.5px; }
        .bottleA6_right { top: 126.5px; left: 309px; }
        .bottleA1_right { top: 102.5px; left: 361px; }
        .bottleA4_right { top: 102.5px; left: 325px; }
        .bottleA7_right { top: 102.5px; left: 289.5px; }

        .bottleB2_right { top: 150px; left: 230px;}
        .bottleB5_right { top: 150px; left: 194px; }
        .bottleB8_right { top: 150px; left: 158.5px; }
        .bottleB3_right { top: 126.5px; left: 210px; }
        .bottleB6_right { top: 126.5px; left: 177.5px; }
        .bottleB1_right { top: 102.5px; left: 230px; }
        .bottleB4_right { top: 102.5px; left: 194px; }
        .bottleB7_right { top: 102.5px; left: 158.5px; }
    }

    .footer {
        gap: 30px;
        border-top: 1px solid rgb(var.$color-blue, 50%);
        padding: 10px 20px;
        font-size: var.$font-size-s;

        .footerItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
        }

        .legend {
            width: 10px;
            height: 10px;
            border-radius: 2px;
        }
    }
        
    .color1 { background-color: rgb(92, 92, 255) };
    .color2 { background-color: rgb(255, 85, 85) };
    .color3 { background-color: rgb(113, 255, 85) };
    .color4 { background-color: rgb(218, 85, 255) };
    .colorTest {background-color: red};

}

.labellingWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
    padding: 10px 20px;
    background-color: rgb(var.$background-color-blue, 10%);
    border-radius: 5px;
    color: var.$color-blue;
    gap: 5px;

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .insertionTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        font-family: var.$font-family-primary-ultra-bold;
    }

    .imageWrapper {
        margin-top: 20px;
        margin-bottom: 20px;
        .img { width: 550px; }
        .labels {
            position: absolute; 
            width: 40px;
            height: 40px;
        }
        .left1 {top: 120px; left: 167px;} .right1 {top: 120px; left: 335px;}
        .left2 {top: 118px; left: 123px;} .right2 {top: 118px; left: 288px;}
        .column {display: flex; flex-direction: column; gap: 10px;}
        .row {display: flex; flex-direction: row; gap: 1px;}
    }

    .footer {
        gap: 30px;
        border-top: 1px solid rgb(var.$color-blue, 50%);
        padding: 10px 20px;
        font-size: var.$font-size-s;

        .footerItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
        }

        .legend {
            width: 10px;
            height: 10px;
            border-radius: 2px;
        }
    }
        
    .color1 { background-color: rgb(92, 92, 255) };
    .color2 { background-color: rgb(255, 85, 85) };
    .color3 { background-color: rgb(113, 255, 85) };
    .color4 { background-color: rgb(218, 85, 255) };

}

.modalWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var.$font-family-primary;

    .cardModal {
        min-width: 650px;
        max-height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var.$background-color-third;
        border-radius: var.$border-radius-large;
        color: var.$font-color-primary;


        .header {
            width: 100%;
            padding: 15px 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: var.$font-family-primary-ultra-bold;
            font-size: var.$font-size-l;
            text-align: center;

            .close_icon {
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: var.$font-size-m;
                border-radius: 50%;
                cursor: pointer;
        
                &:hover {
                color: var.$color-blue;
                }
            }
        }
    }
}
