@use 'src/styles/6-abstracts/variables' as var;
@use 'src/styles/2-components/cards' as card;

.fastordering {
  padding: 0; //Overwrite the card_section because of specific accommodation

  .header_wrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 30px;
    padding-bottom: 10px;
  }

  .body_wrapper {
    width: 100%;
    height: 100%;
    padding: 8px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .hub_wrapper {
      width: 100%; //220px;
      margin: 5px 20px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      margin: 5px 20px;

      .header_wrapper {
        font-family: var.$font-family-primary-bold;
        font-size: var.$font-size-s;
        padding: 5px 10px;
      }

      .body_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 5px;

        .select_wrapper {
          width: max-content;
          display: flex;
          flex-direction: column;
          align-items: start;
          gap: 5px;


          @mixin hubSelection() {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-items: center;
            gap: 10px;
    
            .icon_wrapper {
              width: 40px;
              display: flex;
              flex-direction: row;
              align-items: left;
              gap: 5px;
    
              .abbrev {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-family: var.$font-family-primary-ultra-bold;
                font-size: var.$font-size-xs;
                letter-spacing: 2px;
              }
            }
          }
          .depHubSelection {
            @include hubSelection();
          }
          .arrHubSelection {
            @include hubSelection();
          }
          .arrow {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: start;
          }
        }
      }
    }

    .product_wrapper {
      width: 100%; //220px;
      display: flex;
      flex-direction: column;
      color: white;
      background-color: var.$background-color-blue; 
      border-radius: var.$border-radius-medium; 
      margin: 5px 20px;

      .header_wrapper {
        font-family: var.$font-family-primary-bold;
        font-size: var.$font-size-s;
        padding: 5px 10px;
      }

      .body_wrapper {
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .body_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          font-family: var.$font-family-primary;
          font-size: var.$font-size-s;

          .icon_wrapper {
            width: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }

  .footer_wrapper {
    width: 100%;
    margin: 20px auto 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .order_button {
      height: 40px;
      width: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      box-shadow: var.$shadow-20-primary;
      border-radius: var.$border-radius-medium;
      font-family: var.$font-family-primary-ultra-bold;
      font-size: var.$font-size-l;
      color: var.$font-color-primary;
    }
  }

  @media (min-width: 600px) {
    .body_wrapper {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-right: 0px;
    }
  }

  @media (min-width: 1200px) {
    .body_wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
