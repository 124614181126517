@use 'src/styles/2-components/buttons';
@use 'src/styles/6-abstracts/variables' as var;


  .wrapper {
    width: 650px;
      // width: 100%;
      padding: 10px 20px 25px 30px;
      display: flex;
      flex-direction: column;      
      gap: 10px;
      // background-color: rgb(var.$background-color-blue, 0.1);

      .title_wrapper{
        display: grid;
        grid-template-columns: 0.2fr 1fr 1fr 1fr;
        font-family: var.$font-family-primary;
        font-size: var.$font-size-s;
      }

      .footer_wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgb(224, 224, 224);
        padding-top: 10px;
        margin-top: 10px;

        .listOperations{
          display: flex;
          flex-direction: row;
          gap: 5px;
        }
      }

      .succMsg{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 20px 0px;
        gap: 10px;
        color: var.$color-success;
        font-family: var.$font-family-primary-bold;
        font-size: var.$font-size-l;
      }
      .errMsg{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 20px 0px;
        gap: 10px;
        font-family: var.$font-family-primary-bold;
        font-size: var.$font-size-l;
        color: var.$color-canceled;
      }
  }

  .productItem_wrapper{
    display: grid;
    width: 100%;
    grid-template-columns: 0.2fr 1fr 1fr 1fr;

      .productItem_number{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-family: var.$font-family-primary;
        font-size: var.$font-size-s;
      }

      .body_item {
        display: flex;
        flex-direction: column;
        font-family: var.$font-family-primary;
        font-size: var.$font-size-s;

      }
  }

