@use 'src/styles/2-components/buttons';
@use 'src/styles/6-abstracts/variables' as var;

// Define CSS variables for mui DataGrid Cell design

$statusColorPending: var.$color-pending;
$statusColorScheduled: var.$color-scheduled;
$statusColorApproved: var.$color-approved;
$statusColorComing: var.$color-coming;
$statusColorReady: var.$color-ready;
$statusColorLoading: var.$color-loading;
$statusColorLoaded: var.$color-loaded;
$statusColorInflight: var.$color-in-flight;
$statusColorReadyToUnload: var.$color-ready-to-unload;
$statusColorUnloading: var.$color-unloading;
$statusColorCompleted: var.$color-completed;
$statusColorCancelled: var.$color-canceled;
$statusColorAborted: var.$color-aborted;
$colorBlue: var.$color-blue;

:export {
  statusColorPending: $statusColorPending;
  statusColorScheduled: $statusColorScheduled;
  statusColorApproved: $statusColorApproved;
  statusColorComing: $statusColorComing;
  statusColorReady: $statusColorReady;
  statusColorLoading: $statusColorLoading;
  statusColorLoaded: $statusColorLoaded;
  statusColorInflight: $statusColorInflight;
  statusColorReadyToUnload: $statusColorReadyToUnload;
  statusColorUnloading: $statusColorUnloading;
  statusColorCompleted: $statusColorCompleted;
  statusColorCancelled: $statusColorCancelled;
  statusColorAborted: $statusColorAborted;
  colorBlue: $colorBlue;
}

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var.$font-family-primary;

  .cardModal {
    width: clamp(300px, 80%, 650px);
    max-width: 80%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var.$background-color-third;
    border-radius: var.$border-radius-large;
    color: var.$font-color-primary;

    .header {
      width: 100%;
      padding: 15px 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      font-family: var.$font-family-primary-ultra-bold;
      font-size: var.$font-size-m;
      text-align: center;

      .close_icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        // box-shadow: var.$shadow-20-primary;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          // background-color: var.$background-color-fifth;
          color: var.$color-blue;
        }
      }
    }

    .content {
      width: 100%;
      padding: 10px 20px 40px 20px;
      display: grid;
      grid-template-columns: 0.5fr 1fr;
      gap: 50px;

      .labelWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .labelWrapper img {
        width: 100px; /* Adjust as needed */
        height: auto; /* Maintain aspect ratio */
      }
      
      /* Alternate position for odd-indexed images */
      .labelWrapper img:nth-child(odd) {
        transform: translateX(20%);
        margin-bottom: -25px; /* Adjust spacing between images */
      }
      
      /* Alternate position for even-indexed images */
      .labelWrapper img:nth-child(even) {
        transform: translateX(-20%);
        margin-bottom: -25px; /* Adjust spacing between images */
      }

      /* Alternate position for even-indexed images */
      .labelWrapper img:last-child {
        transform: translateX(-20%);
        margin-bottom: 0px;
      }

      .info_card {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .info_card_header {
          font-family: var.$font-family-primary-ultra-bold;
          // text-transform: uppercase;
          color: var.$color-blue;
          font-size: var.$font-size-s;
        }

        .info_card_content {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 30px;
        }
      }
    }

    .confirmation_header {
      width: 100%;
      padding: 40px 40px 0 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: var.$font-family-primary-ultra-bold;
      font-size: var.$font-size-2xl;
      text-align: center;
    }

    .confirmation_content {
      // width: 100%;
      padding: 40px;
      display: flex;
      gap: 40px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: var.$font-family-primary;
      font-size: var.$font-size-l;
    }

    .footer {
      width: 100%;
      padding: 0 40px 20px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: var.$font-family-primary;
      font-size: var.$font-size-m;
      text-align: center;

      .cancelButton {
        padding: 5px 8px;
        background-color: #eb5a5a;
        border: none;
        border-radius: 5px;
        outline: none;
        color: var.$font-color-secondary;
        text-transform: capitalize;

        &:disabled {
          opacity: 20%;
        }

        &:hover {
          background-color: #ff0000;
          color: var.$font-color-secondary;
        }
      }

      .prepareButton {
        padding: 5px 8px;
        background-color: #eb5a5a;
        border: none;
        border-radius: 5px;
        outline: none;
        color: var.$font-color-secondary;

        &:disabled {
          opacity: 20%;
        }

        &:hover {
          background-color: #ff0000;
          color: var.$font-color-secondary;
        }
      }

      .cancelCancellation {
        padding: 5px 8px;
        background-color: rgba(var.$background-color-fifth, 0.5);
        border: none;
        border-radius: 5px;
        outline: none;
        color: var.$font-color-primary;

        &:hover {
          background-color: rgba(var.$background-color-fifth, 1);
          color: var.$font-color-primary;
        }
      }
    }
  }
}

.itemDetail {
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
  // font-size: var.$font-size-m;

  .itemKey {
    justify-self: flex-start;
    text-align: left;
    font-size: var.$font-size-xs;
    text-transform: capitalize;
  }

  .itemValue {
    justify-self: flex-start;
    font-family: var.$font-family-primary-bold;
    text-align: left;
    max-width: 150px;
  }
}
